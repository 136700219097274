import React from 'react';
import { useDispatch } from 'react-redux';
import AIQAutocomplete from '../AIQAutocomplete/AIQAutocomplete';
import AIQInput from '../AIQInput/AIQInput';
import { findACValue, getOptionLabel } from './helpers';
import { useRegisterField } from './hooks/useRegisterField';

/* eslint-disable react/prop-types */
function Select(props) {
    const {
        fieldName,
        initialValue = null,
        value,
        label,
        touched,
        type,
        error,
        formId,
        required,
        lookup,
        disabled,
        blurHandler = () => {},
        changeHandler = () => {},
        inputValueChangeHandler = () => {},
        automation,
        validationStyles,
        containerClasses,
        forceFieldRegistration,
        helperText,
        optionListRenderer,
        optionLabelRenderer,
        inputRenderer,
    } = props;

    const dispatch = useDispatch();

    useRegisterField({
        formId,
        fieldName,
        initialValue,
        disabled,
        required,
        forceFieldRegistration,
        dispatch,
    });

    const acValue = findACValue({ lookup, value, referenceField: props.referenceField, referenceValue: props.referenceValue });

    const hasError = touched && error;
    const backgroundColor = validationStyles?.color ? { backgroundColor: validationStyles.color } : null;

    return (
        <AIQAutocomplete
            optionListRenderer={optionListRenderer}
            optionLabelRenderer={optionLabelRenderer}
            valueExpr={lookup?.valueExpr}
            getOptionLabel={lookup?.displayExpr && getOptionLabel(lookup.displayExpr)}
            value={acValue || null}
            onInputChange={inputValueChangeHandler.bind({ lookup, formId, fieldName, ...props })}
            onChange={changeHandler.bind({ lookup, formId, fieldName, ...props })}
            onBlur={blurHandler.bind({ required, touched, error, formId, fieldName, validationField: props.validationField })}
            freeSolo={!lookup?.displayExpr || props.referenceField}
            options={lookup?.dataSource}
            containerClasses={containerClasses}
            renderInput={(params) => (
                <AIQInput
                    {...params}
                    inputRenderer={inputRenderer}
                    helperText={`${helperText && hasError ? helperText : ''}`}
                    label={label}
                    type={type}
                    error={hasError}
                    name={fieldName}
                    InputLabelProps={{ shrink: true }}
                    automation={automation}
                    disabled={disabled}
                    alerts={acValue?.authTypes}
                    {...backgroundColor}
                />
            )}
        />
    );
}

export default React.memo(Select);
