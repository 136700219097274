import { ACCESS_POINT_RECORDS_REQUEST_PAGE_SIZE } from 'components/accessPointRecords/constants/accessPointRecordTableConstant.js';
import { axiosLasta } from 'config';
import { toQueryString } from 'utils/queryStringHelpers.js';

export const fetchAccessPointRecordsByLocation = async (locationId, filters) => {
    const pageNum = 0;
    const size = ACCESS_POINT_RECORDS_REQUEST_PAGE_SIZE;
    const { startDate, endDate } = filters ?? {};
    const query = toQueryString({
        locationId,
        size,
        page: pageNum,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
    });
    const response = await axiosLasta(`/access-point-records?${query}`);
    const { data } = response;
    return data;
};

export const fetchAccessPointRecordsColumnByLocation = async (locationId) => {
    const response = await axiosLasta(`/access-point-records/columns?locationId=${locationId}`);
    const { data } = response;
    const groupedFields = groupFieldsByHeader(data?.fields);
    return groupedFields;
};

export const fetchAccessPointRecordsFormFieldsByLocation = async (locationId) => {
    const response = await axiosLasta(`/access-point-records/form/fields?locationId=${locationId}`);
    const { data } = response;
    const groupedFields = groupFieldsByHeader(data?.fields);
    return groupedFields;
};

export const createAccessPointRecord = async (payload) => {
    const response = await axiosLasta.post('/access-point-records', payload);
    return response.data;
};

export const updateAccessPointRecord = async (payload, id) => {
    const response = await axiosLasta.put(`/access-point-records/${id}`, payload);
    return response.data;
};

/**
 * Groups fields by their header and returns an array of objects.
 *
 * Each object in the resulting array contains a `caption`, which is the
 * header of the grouped fields, and a `columns` array that holds the
 * corresponding fields associated with that header.
 *
 * @param {Array} fields - An array of field objects where each object
 *                         must contain a `header` property.
 * @returns {Array} An array of objects, each containing:
 *                  - `caption`: The header of the group.
 *                  - `columns`: An array of field objects associated
 *                                with that header.
 *
 * @example
 *  Input:
 *  const fields = [
 *     { '@type': 'Field', caption: 'Started', dataField: 'startedDateTime', header: 'General' },
 *     { '@type': 'Field', caption: 'Direction', dataField: 'gateDirection', header: 'General' },
 *     { '@type': 'Field', caption: 'Full name', dataField: 'person.fullName', header: 'Person' },
 *  ];
 *
 *  Output:
 *  [
 *      { caption: 'General', columns: [ * General fields * ] },
 *      { caption: 'Person', columns: [ * Person fields * ] }
 *  ]
 */
const groupFieldsByHeader = (fields) => {
    return fields.reduce((acc, field) => {
        const { header, caption } = field;
        // Check if the header already exists in the accumulator
        let group = acc.find((g) => g.caption === header);

        if (!group) {
            // If it doesn't exist, create a new group
            group = { caption: header, columns: [], type: 'object' };
            acc.push(group);
        }

        // if the field is type list then add type to the group
        if (field.dataType === 'list') {
            group.type = field.dataType;
            group.dataField = field.dataField;
        } else {
            // Push the field into the corresponding group's columns
            group.columns.push(field);
        }
        return acc;
    }, []);
};

export const removeAccessPointRecordsByLocation = async (transactionId) => {
    const response = await axiosLasta.delete(`/access-point-records/delete/${transactionId}`);
    const { data } = response;
    return data;
};
