import { createTheme } from '@mui/material/styles';

let theme = createTheme({
    // Theme customization goes here as usual, including tonalOffset and/or
    // contrastThreshold as the augmentColor() function relies on these
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: 'var(--color-dark-grey)',
                    backgroundColor: 'var(--color-white)',
                    boxShadow: '1px 1px 5px #5d6d7e80',
                    border: '1px solid #5d6d7e80',
                },
            },
        },
    },
    MuiTextField: {
        defaultProps: {
            InputLabelProps: { shrink: true },
        },
    },
});

theme = createTheme(theme, {
    typography: {
        subtitle1: {
            fontSize: 14,
            color: '#828282',
        },
        formLabel: {
            fontSize: 16,
            color: '#828282',
            fontWeight: 500,
        },
        helpText: {
            fontSize: 14,
            color: '#1976d2',
        },
    },
    // Custom colors created with augmentColor go here
    palette: {
        gray3: theme.palette.augmentColor({
            color: {
                main: '#828282',
            },
            name: 'gray3',
        }),
    },
});
export const DEFAULT_THEME = theme;
