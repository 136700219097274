export const getOptionLabel = (displayExpr) => (option) => option?.[displayExpr] || '';

export const findACValue = ({ lookup, value, referenceField, referenceValue }) => {
    return (
        lookup?.dataSource.find((data) => {
            let activeOption = false;
            if (data?.[lookup.displayExpr]) {
                activeOption = data?.[lookup.displayExpr] === value || data?.[lookup.displayExpr] === value?.[lookup.displayExpr];
            }
            if (data?.[lookup.valueExpr]) {
                activeOption = data?.[lookup.valueExpr] === value || data?.[lookup.valueExpr] === value?.[lookup.valueExpr];
            }
            if (referenceField && referenceValue) {
                activeOption = data?.[lookup.referenceExpr] === referenceValue;
            }
            return activeOption;
        }) ?? value
    );
};
