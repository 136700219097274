import { CircularProgress, Stack } from '@mui/material';
import CustomButton from 'components/UI/buttons/CustomButton.style.js';
import PropTypes from 'prop-types';

function FormActions({ submitHandler, discardHandler, isLoading }) {
    return (
        <Stack padding={0} gap={2} direction="row" justifyContent="flex-end">
            <CustomButton customVariant="error" onClick={discardHandler}>
                Cancel
            </CustomButton>
            <CustomButton customVariant="success" onClick={submitHandler} disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} /> : 'Save '}
            </CustomButton>
        </Stack>
    );
}

export default FormActions;

FormActions.propTypes = {
    submitHandler: PropTypes.func.isRequired,
    discardHandler: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};
