import Alerts from 'components/UI/Alerts/Alerts';
import React, { useEffect, useRef, useState } from 'react';
import styles from './AIQInput.module.css';
import Automation from './Automation/Automation';
import Input from './Input/Input';
import Label from './Label/Label';
let componentId = 1;

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function AIQInput(props) {
    const { label, automation, backgroundColor, borderColor, disabled, error, helperText, alerts } = props;

    const [focused, setFocused] = useState(false);
    const [id] = useState(componentId++);
    const hasAutomation = automation && Object.keys(automation).length;
    const containerRef = useRef();
    const labelRef = useRef();

    useEffect(() => {
        const { current: containerElement } = containerRef;
        const { current: labelElement } = labelRef;

        if (backgroundColor) {
            containerElement.style.backgroundColor = backgroundColor;
            labelElement.style.outline = `1px solid ${backgroundColor}`;
            // labelElement.style.color = mainColor;
        }
        if (borderColor) {
            containerElement.style.outline = `2px solid ${borderColor}`;
            labelElement.style.backgroundColor = borderColor;
            labelElement.style.color = 'white';
        }
        return () => {
            containerElement?.removeAttribute('style');
            labelElement?.removeAttribute('style');
        };
    }, [backgroundColor, borderColor]);

    const containerStyles = getContainerStyles({
        focused,
        hasAutomation,
        disabled,
        error,
    });

    const inputStyles = `${styles.input}${backgroundColor || hasAutomation ? ` ${styles['input--border-color']}` : ''}`;

    return (
        <div className={styles['wrapper-container']}>
            <div className={containerStyles} ref={containerRef}>
                {label && <Label {...{ id, label, focused, error }} ref={labelRef} disabled={disabled} />}
                <Automation disabled={disabled} automation={automation} />
                <Input {...{ inputStyles, setFocused, id, focused, ...props }} />
            </div>
            {/* {helperText !== undefined && ( */}
            <p className={styles['helper-text']}>{helperText}</p>
            {/* )} */}
        </div>
    );
}

export default React.memo(AIQInput);

function getContainerStyles({ focused, hasAutomation, disabled, error }) {
    return `${styles['input-container']}${focused ? ` ${styles['input-container--focus']}` : ''}${
        hasAutomation ? '' : ` ${styles['input-container--input-only']}`
    }${disabled ? '' : ` ${styles['input-container--standard']}`}${
        error
            ? focused
                ? ` ${styles['input-container--error']} ${styles['input-container--focus--error']}`
                : ` ${styles['input-container--error']}`
            : ''
    }`;
}
