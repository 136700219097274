import { isValid, format, startOfDay, endOfDay } from 'date-fns';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mma';
export const DATE_TIME_LONG_FORMAT = 'd MMMM yyyy hh:mma';
export const TIME_FORMAT = 'HH:mm:ss';
export const DEFAULT_START_TIME = startOfDay(new Date());
export const DEFAULT_END_TIME = endOfDay(new Date());

export const fromIsoString = (isoString) => {
    const date = new Date(isoString);
    return isValid(date) ? date : null;
};

export const isValidDate = (date) => {
    return isValid(date);
};

export const formatDate = (date, dateFormat = DATE_FORMAT) => {
    if (!date) {
        return '';
    }
    return format(date, dateFormat);
};

export const formatDateTime = (dateTime, dateFormat = DATE_TIME_FORMAT) => {
    if (!isValid(dateTime)) {
        return '';
    }
    return format(dateTime, dateFormat);
};

export const timeDifferentFriendlyName = (dateTime) => {
    const targetDate = new Date(dateTime);
    const now = new Date();

    const timeDifference = targetDate - now;

    if (timeDifference <= 0) {
        return '';
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    if (days === 1) return `Tomorrow ${formatDate(dateTime, 'hh:mma')}`;
    else if (days === 0) {
        const hourText = hours === 0 ? '' : hours === 1 ? `${hours} hour ` : `${hours} hours `;
        const minuteText = minutes === 1 ? `${minutes} minute left` : `${minutes} minutes left`;
        return hourText + minuteText;
    } else return formatDate(dateTime, 'd MMMM, yyyy, hh:mma');
};

export const formatTime = (time) => {
    return time.slice(0, 5).replace(':', '.');
};

export const generateDefaultTimeRestrictions = () => {
    const start = DEFAULT_START_TIME;
    const end = DEFAULT_END_TIME;

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const timeRestrictions = days.reduce((acc, day) => {
        acc[day] = { start, end, checked: true };
        return acc;
    }, {});

    return { timeRestrictions, start, end };
};
