import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ssoenabled: null,
};

const featureFlagSlice = createSlice({
    name: 'featureFlag',
    initialState,
    reducers: {
        setEnableSso(state, { payload }) {
            state.ssoenabled = payload;
        },
        getFeatureFlagData() {},
    },
});

export const featureFlagActions = featureFlagSlice.actions;
export const featureFlagReducer = featureFlagSlice.reducer;
