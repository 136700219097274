import axios from 'axios';

export const PROFILE = import.meta.env.VITE_ENV;

const service_port = import.meta.env.VITE_SERVICE_PORT;
const host_name = import.meta.env.VITE_HOSTNAME;

const protocol_http = import.meta.env.VITE_PROTOCOL;
const isLocalHost = host_name === 'localhost';
const domain = process.env.NODE_ENV === 'test' || !isLocalHost ? host_name : 'localhost:' + service_port;

function getServiceUrlSuffix() {
    switch (PROFILE) {
        case 'production':
            return '';
        case 'staging':
            return '-staging';
        case 'develop':
            return '-develop';
        default:
            return '-develop';
    }
}

const service_url_suffix = getServiceUrlSuffix();

const SSOAdresses = {
    LOCAL: {
        REDIRECT_TO: 'http://localhost:3001',
        MAIN: 'http://localhost:8080',
    },
    ALERT: `wss://alert${service_url_suffix}.birdseyesecurity.com/socket`,
    DEPLOYED: `https://sso${service_url_suffix}.birdseyesecurity.com`,
    CONTROLCENTAR: `wss://lasta${service_url_suffix}.birdseyesecurity.com/socket`,
    // CONTROLCENTAR: `ws://localhost:5000/socket`,
};

export const MAIN_ADDRESS = protocol_http + domain;
export const SSO_REDIRECT_TO_ADDRESS = isLocalHost ? SSOAdresses.LOCAL.REDIRECT_TO : SSOAdresses.DEPLOYED;
export const ALERT_SERVICE_WEBSOKET = SSOAdresses.ALERT;
export const CONTROL_CENTAR_WEBSOKET = SSOAdresses.CONTROLCENTAR;

export const axiosLasta = axios.create({
    baseURL: MAIN_ADDRESS,
    withCredentials: true,
});

export const axiosSSO = axios.create({
    baseURL: isLocalHost ? SSOAdresses.LOCAL.MAIN : SSOAdresses.DEPLOYED,
    withCredentials: true,
});

export const snackBarGeneralConfiguration = {
    maxSnack: 7, //maxNumberOfNotificationsOnScreen
    autoHideDuration: 6000, //number of ms before hiding snackbar general config, can be overwritten for each snackbar when initialising it
};

// number of items in per page for Access Point Records table
export const accessPointRecordTableRowsPerPage = 750;
