import Alerts from 'components/UI/Alerts/Alerts';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './Option.module.css';

const defaultOptionLabelRenderer = (props) => {
    const { option, getInputValue, optionRef, optionStyles, mouseDownHandler, mouseOverHandler } = props;
    const optionLabel = getInputValue(option);
    return (
        <div className={`${styles['option-container']} ${optionStyles}`} onMouseDown={mouseDownHandler} onMouseOver={mouseOverHandler}>
            <Alerts alerts={[{ color: option.color }]} />
            <p className={styles['option-text']} ref={optionRef}>
                {optionLabel}
            </p>
        </div>
    );
};

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function Option(props) {
    const {
        option,
        onChange,
        setDropdownOpened,
        setInputValue,
        activeOption,
        setHoveredOption,
        hoveredOption,
        selectedOption,
        setSelectedOptionIndex,
        optionIndex,
        getInputValue,
        optionLabelRenderer,
    } = props;
    const optionRef = useRef();

    useEffect(() => {
        if (activeOption) setSelectedOptionIndex(optionIndex);
    }, [activeOption, optionIndex, setSelectedOptionIndex]);

    useEffect(() => {
        if (selectedOption)
            optionRef.current?.scrollIntoView({
                block: 'nearest',
                inline: 'start',
            });
    }, [selectedOption]);

    const optionStyles = `${styles['option']}${activeOption ? ` ${styles['option--active']}` : ''}${
        hoveredOption ? ` ${styles['option--highlighted']}` : selectedOption ? ` ${styles['option--selected']}` : ''
    }`;

    const mouseDownHandler = useCallback(
        (e) => {
            setInputValue(getInputValue(option));
            onChange(e, option);
            setDropdownOpened(false);
        },
        [getInputValue, onChange, option, setDropdownOpened, setInputValue]
    );

    const mouseOverHandler = useCallback(
        (e) => {
            setHoveredOption(option);
            setSelectedOptionIndex(optionIndex);
        },
        [option, optionIndex, setHoveredOption, setSelectedOptionIndex]
    );

    const renderedOptions = useMemo(
        () =>
            optionLabelRenderer
                ? optionLabelRenderer(defaultOptionLabelRenderer, { ...props, optionRef, optionStyles, mouseDownHandler, mouseOverHandler })
                : defaultOptionLabelRenderer({ ...props, optionRef, optionStyles, mouseDownHandler, mouseOverHandler }),
        [optionLabelRenderer, props, optionStyles, mouseDownHandler, mouseOverHandler]
    );

    return <>{renderedOptions}</>;
}

export default Option;
