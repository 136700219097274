import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Navigation.module.css';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DrawerListItem from './DrawerListItem/DrawerListItem';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import UserNavigation from 'components/lorriniCompnents/navigation/userNavigation/UserNavigation';
import birdLogo from 'assets/BirdsEyeLogo.svg';
import { useTopNavRouteData } from 'components/appRoute/hooks/useTopNavRouteData.js';
import LastaDialog from 'components/UI/dialogs/Dialog';

function NavigationDrawer({ user }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);

    const toggleDrawerOpenHandler = () => setDrawerOpen((state) => !state);

    const { topNavMiddleAdornment, helpDialogComponent, pageTitle } = useTopNavRouteData();

    const handleTriggerHelpDialog = (isOpen) => {
        if (helpDialogComponent) {
            setHelpDialogOpen(isOpen);
        }
    };

    const helpDialogTitle = useMemo(() => (pageTitle ? `Help Section - ${pageTitle}` : 'Help Section'), [pageTitle]);

    return (
        <div className={styles.navigation}>
            <div className={styles.leftSection}>
                <IconButton onClick={toggleDrawerOpenHandler}>
                    <MenuIcon sx={{ color: 'white' }} />
                </IconButton>
                <Link className={styles.logo} to={'/'}>
                    <img src={birdLogo} alt="BirdsEyeLogo" />
                </Link>
            </div>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawerOpenHandler}>
                <List sx={{ paddingTop: 'var(--header__height--large)' }}>
                    <DrawerListItem user={user} toggleDrawer={toggleDrawerOpenHandler} />
                    <Divider />
                </List>
            </Drawer>
            {topNavMiddleAdornment}
            <UserNavigation user={user} onClickHelp={() => handleTriggerHelpDialog(true)} />

            <LastaDialog
                icon={<HelpOutlinedIcon sx={{ fill: 'var(--color-white)', width: '2rem', height: '2rem' }} />}
                title={helpDialogTitle}
                sxTitleText={{ color: 'var(--color-white)', fontSize: '1rem', fontWeight: 700 }}
                sxTitle={{ backgroundColor: 'var(--color-blue)' }}
                sxCloseButton={{ color: 'var(--color-white)' }}
                sxContent={{ padding: '0rem', overflowY: 'hidden' }}
                open={helpDialogOpen}
                onClose={() => handleTriggerHelpDialog(false)}
            >
                {helpDialogComponent}
            </LastaDialog>
        </div>
    );
}

export default NavigationDrawer;

NavigationDrawer.propTypes = {
    user: PropTypes.object,
};
