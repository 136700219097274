import { routes } from '../navigationRoutes';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { SSO_REDIRECT_TO_ADDRESS } from 'config';
import PropTypes from 'prop-types';
import DrawerItem from './DrawerItem';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { ROUTE_PATH } from 'utils/constants';

const homeButtonClickHandler = () => window.location.assign(SSO_REDIRECT_TO_ADDRESS);

const DrawerListItem = ({ user, toggleDrawer }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentItems, setCurrentItems] = useState([]);
    const { isSsoEnabled } = useFeatureFlag();

    const handlePopoverOpen = (event, items) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCurrentItems(items);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setCurrentItems([]);
    };

    const open = Boolean(anchorEl);

    const isRouteAllowed = (route) => {
        const userRoles = user.roles;
        const allowedRoles = route?.allowedRoles || [];
        // If no roles are defined, it's allowed by default
        if (!allowedRoles.length) return true;
        return userRoles.some((role) => allowedRoles.includes(role));
    };

    return (
        <List sx={{ padding: 0 }}>
            <DrawerItem
                route={{
                    path: '/',
                    text: 'Birdseye Home',
                    Icon: HomeIcon,
                }}
                onClick={homeButtonClickHandler}
            />
            <Divider />
            {/* Render route has sub item */}
            {routes.map((route) => {
                if (!isSsoEnabled && route.path === ROUTE_PATH.SSO_CONFIGURATION) return null;
                if (!isRouteAllowed(route)) return;
                if (route.items) {
                    return (
                        <React.Fragment key={route.text}>
                            <DrawerItem route={route} onClick={(e) => (open ? handlePopoverClose() : handlePopoverOpen(e, route.items))} />
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {/* Render list sub route items */}
                                <Box>
                                    {currentItems.map((item) => (
                                        <DrawerItem
                                            key={item.path}
                                            route={item}
                                            onClick={() => {
                                                handlePopoverClose();
                                                toggleDrawer();
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Popover>
                        </React.Fragment>
                    );
                }
                // Render normal list item if no children
                return <DrawerItem key={route.path} route={route} onClick={toggleDrawer} />;
            })}
        </List>
    );
};

export default DrawerListItem;

DrawerListItem.propTypes = {
    user: PropTypes.object,
    toggleDrawer: PropTypes.func,
};
