import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { selectUser } from 'store/auth/authSelectors';
import { fetchLocationsStart, fetchLocationsSuccess, fetchLocationsError, setSelectedLocationId } from './globalLocationSlice';
import { fetchUserLocationForCurrentUser, fetchAllLocations } from 'services/userLocationService.js';
import { selectSelectedLocationId } from 'components/globalLocationSelector/store/globalLocationSelectors.js';
import { ROLES } from 'utils/constants.js';

// Worker saga: will be fired on fetchLocationsStart actions
function* fetchLocations() {
    try {
        const user = yield select(selectUser);
        const isLoadAllLocations = user?.roles?.includes(ROLES.ADMIN) || user?.roles?.includes(ROLES.AGENT);
        const data = yield isLoadAllLocations ? fetchAllLocations() : fetchUserLocationForCurrentUser();
        const sortedData = data?.sort((a, b) => a.yardName?.localeCompare(b.yardName));
        yield put(fetchLocationsSuccess(sortedData));
        yield call(setDefaultSelectedLocation, sortedData);
    } catch (error) {
        yield put(fetchLocationsError(error.message));
    }
}

function* setDefaultSelectedLocation(locationList) {
    const selectedLocationId = yield select(selectSelectedLocationId);
    if (locationList?.length && !selectedLocationId) {
        const firstLocation = locationList[0];
        yield put(setSelectedLocationId(firstLocation.id));
        return;
    }
    const selectedLocation = locationList.find((location) => location.id === selectedLocationId);
    // The selected location is not in the list of locations
    //  => reset selected location to null
    if (!selectedLocation) {
        yield put(setSelectedLocationId(null));
    }
}

// Watcher saga: spawns a new fetchLocations task on each fetchLocationsStart
function* onFetchLocationsStart() {
    yield takeLatest(fetchLocationsStart.type, fetchLocations);
}

// Root saga: combines all the watcher sagas
export function* globalLocationSaga() {
    yield all([call(onFetchLocationsStart)]);
}
