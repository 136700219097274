import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Stack } from '@mui/material';
import { useCallback, useMemo, useRef } from 'react';
import styles from './Input.module.css';

const defaultInputRenderer = (props) => {
    const {
        id,
        name,
        disabled,
        value,
        type,
        placeholder,
        autoFocus,
        defaultValue,
        onChange = () => {},
        inputStyles,
        inputProps,
        focused,
        dropdownArrow,
        handleBlur,
        handleFocus,
        inputRef,
    } = props;
    return (
        <>
            <input
                id={id}
                name={name}
                disabled={disabled}
                className={inputStyles}
                value={value}
                type={type}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={placeholder}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                autoComplete="off"
                spellCheck="false"
                {...inputProps}
                ref={inputRef}
            />
            {!focused && dropdownArrow && (
                <>
                    <ArrowDropDownRoundedIcon
                        sx={{
                            position: 'absolute',
                            right: '-0.2rem',
                            bottom: '0',
                            color: `${disabled ? '#00000061' : 'rgba(0, 0, 0, 0.5)'}`,
                            cursor: 'pointer',
                        }}
                        onClick={() => inputRef.current.focus()}
                    />
                </>
            )}
        </>
    );
};

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function Input(props) {
    const { value, onChange = () => {}, onBlur = () => {}, setFocused, automation, onFocus, inputRenderer } = props;
    const inputRef = useRef();

    const handleFocus = useCallback(
        (e) => {
            setFocused(true);
            onFocus && onFocus(true);
        },
        [onFocus, setFocused]
    );

    const handleBlur = useCallback(
        (e) => {
            setFocused(false);
            onFocus && onFocus(false);

            if (value === '' && automation?.sourceValue) {
                e.target.value = automation?.sourceValue;
                onChange(e);
            }

            onBlur(e);
        },
        [automation?.sourceValue, onBlur, onChange, onFocus, setFocused, value]
    );

    const renderedInput = useMemo(() => {
        const rendererProps = { ...props, handleBlur, handleFocus, inputRef };
        return inputRenderer ? inputRenderer(defaultInputRenderer, rendererProps) : defaultInputRenderer(rendererProps);
    }, [handleBlur, handleFocus, inputRenderer, props]);

    return (
        <div className={styles['input-wrapper']}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} gap={5}>
                {renderedInput}
            </Stack>
        </div>
    );
}

export default Input;
