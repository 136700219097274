import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.css';
import Options from './Options/Options';

const defaultOptionListRenderer = (props) => {
    const { filteredOptions, ...otherProps } = props;
    return <Options {...otherProps} filteredOptions={filteredOptions} />;
};

function Dropdown({ dropdownOpened, noOptionsText, ...props }) {
    const { filteredOptions, freeSolo, optionListRenderer } = props;

    const renderedDropdownOptions = useMemo(() => {
        return optionListRenderer ? optionListRenderer(defaultOptionListRenderer, props) : defaultOptionListRenderer(props);
    }, [optionListRenderer, props]);

    return (
        <div className={styles['dropdown']}>
            {dropdownOpened && !!filteredOptions.length && renderedDropdownOptions}
            {dropdownOpened && !filteredOptions.length && !freeSolo && <p className={styles['no-option']}>{noOptionsText}</p>}
        </div>
    );
}

Dropdown.propTypes = {
    dropdownOpened: PropTypes.bool.isRequired,
    noOptionsText: PropTypes.string,
    filteredOptions: PropTypes.array.isRequired,
    freeSolo: PropTypes.bool,
    optionListRenderer: PropTypes.func,
};

Dropdown.defaultProps = {
    noOptionsText: 'No options available',
    freeSolo: false,
    optionListRenderer: null,
};

export default Dropdown;
