import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { featureFlagActions } from './featureFlagSlice';
import { axiosLasta } from 'config';
import notificationsHandler from 'utils/notificationsHandler';
import { selectSsoEnabled } from 'store/featureFlag/featureFlagSelector';

function* getSsoEnabled() {
    try {
        const enabledSso = yield select(selectSsoEnabled);
        if (enabledSso !== null) return;
        const enableSsoResponse = yield axiosLasta('/feature-flags');
        const { ssoenabled } = enableSsoResponse?.data || {};
        yield put(featureFlagActions.setEnableSso(ssoenabled));
    } catch (err) {
        yield call(notificationsHandler, {
            err,
            title: 'Error getting enabled sso data!',
        });
    }
}

function* onGetFeatureFlagData() {
    yield takeLatest(featureFlagActions.getFeatureFlagData.type, getSsoEnabled);
}

export function* featureFlagSaga() {
    yield all([call(onGetFeatureFlagData)]);
}
