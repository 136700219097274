import { createSlice } from '@reduxjs/toolkit';
import makeObjectPropertiesBlank from 'utils/objectHelper';

const initialState = {
    // fetchTransactionFormConfigInProgress: false,
    // fetchTransactionFormConfigError: null,

    // initializingFormInProgress: false,
    // initializingFormError: null,

    submitFormInProgress: false,
    submitFormError: null,

    forms: {},
};

const formControlSlice = createSlice({
    name: 'formControl',
    initialState,
    reducers: {
        registerNewForm(state, { payload: formId }) {
            state.forms[formId] = {
                form: {
                    values: {},
                    errors: {},
                    touched: {},
                },
            };
        },

        registerNewFormField(state, { payload: { formId, fieldName, initialValue, disabled, required } }) {
            // if (disabled) return;

            const form = state.forms[formId]?.form;

            form.values[fieldName] = initialValue;
            if (!required) return;
            form.errors[fieldName] = true;
            form.touched[fieldName] = false;
        },

        setFieldValue(state, { payload: { formId, fieldName, value } }) {
            const form = state.forms[formId].form;
            form.values[fieldName] = value;
        },

        setFieldTouched(state, { payload: { formId, fieldName, touched = true } }) {
            const form = state.forms[formId].form;
            form.touched[fieldName] = touched;
        },

        setFieldError(state, { payload: { formId, fieldName, error = true } }) {
            const form = state.forms[formId].form;
            form.errors[fieldName] = error;
        },

        removeFieldError(state, { payload: { formId, fieldName } }) {
            const form = state.forms[formId].form;
            const { [fieldName]: toBeDeleted, ...rest } = form.errors || {};
            form.errors = rest;
        },

        removeForm(state, { payload: formId }) {
            const { [formId]: toBeDeleted, rest } = state.forms;
            state.forms = { ...rest };
        },
        clearForm(state, { payload: formId }) {
            const form = state.forms[formId].form;
            const { values, errors, touched } = form || {};

            state.forms[formId] = {
                form: {
                    values: makeObjectPropertiesBlank(values),
                    errors: errors,
                    touched: makeObjectPropertiesBlank(touched),
                },
            };
        },

        setFormValues(state, { payload: { formId, newFormValues } }) {
            const form = state.forms[formId].form;
            const { values, errors, touched } = form || {};
            Object.keys(values).forEach((fieldName) => {
                const hasIncomingValue = newFormValues && Object.prototype.hasOwnProperty.call(newFormValues, fieldName);

                let valueToSet = hasIncomingValue ? newFormValues[fieldName] : values[fieldName];

                if (!hasIncomingValue && (valueToSet || valueToSet === undefined))
                    switch (typeof valueToSet) {
                        case 'boolean':
                            valueToSet = false;
                            break;
                        case 'string':
                            valueToSet = '';
                            break;

                        default:
                            valueToSet = null;
                    }

                values[fieldName] = valueToSet;
                Object.prototype.hasOwnProperty.call(errors, fieldName) &&
                    (errors[fieldName] = !(hasIncomingValue && (valueToSet || valueToSet === false)));
                Object.prototype.hasOwnProperty.call(touched, fieldName) && (touched[fieldName] = !!hasIncomingValue);
            });
        },

        setSubmitFormInProgress(state, { payload: submitFormInProgress }) {
            state.submitFormInProgress = submitFormInProgress;
        },
    },
});

export const formControlActions = formControlSlice.actions;
export const formControlReducer = formControlSlice.reducer;
