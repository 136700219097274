function makeObjectPropertiesBlank(formValues) {
    for (const key in formValues) {
        let valueToSet = formValues[key];
        if (typeof valueToSet === 'object' && valueToSet !== null) {
            if (Array.isArray(valueToSet)) {
                formValues[key] = null;
            } else makeObjectPropertiesBlank(valueToSet);
        } else {
            switch (typeof valueToSet) {
                case 'boolean':
                    valueToSet = false;
                    break;
                case 'string':
                    valueToSet = '';
                    break;
                default:
                    valueToSet = null;
            }

            formValues[key] = valueToSet;
        }
    }

    return formValues;
}
export default makeObjectPropertiesBlank;
