import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthoriziedItemList } from 'store/companyAuthorizedList/companyAuthorizationListSelectors';
import { selectMasterList } from 'store/organizationList/organizationListSelectors';
import { ACCESS_POINT_RECORDS_TABLE_ID } from '../constants/accessPointRecordTableConstant';
import {
    selectAccessPointRecordsColumnList,
    selectAccessPointRecordsDataByTableId,
    selectAccessPointRecordsFilterDateRange,
    selectAllFilter,
    selectFetchingAccessPointRecordsColumnError,
    selectFetchingAccessPointRecordsColumnInProgress,
    selectFetchingAccessPointRecordsError,
    selectFetchingAccessPointRecordsInProgress,
    selectRemovingAccessPointRecordError,
    selectRemovingAccessPointRecordInProgress,
    selectSearchText,
    selectSelectedFilter,
} from '../store/accessPointRecordsSelectors';
import {
    fetchAccessPointRecordsColumnStart,
    fetchAccessPointRecordsStart,
    removeAccessPointRecordsStart,
    setAllFilter,
    setFilterDateRange,
    setSearchText,
    setSelectedFilter,
} from '../store/accessPointRecordsSlice';
import { organizationListActions } from 'store/organizationList/organizationListSlice';
import { companyAuthorizationListActions } from 'store/companyAuthorizedList/companyAuthorizationListSlice';
import { peopleAuthorizedListActions } from 'store/peopleAuthorizedList/peopleAuthorizedListSlice';
import * as authorizedPeopleSelectors from 'store/peopleAuthorizedList/peopleAuthorizedListsSelectors';

const useAccessPointRecord = () => {
    const dispatch = useDispatch();

    const dispatchFetchAccessPointRecords = useCallback(
        (locationId) => {
            dispatch(fetchAccessPointRecordsStart({ locationId }));
            dispatch(companyAuthorizationListActions.fetchAuthorizedListPerLocation({ id: locationId }));
            dispatch(peopleAuthorizedListActions.fetchAuthorizedPersonPerLocation({ id: locationId }));
        },
        [dispatch]
    );

    const dispatchFetchAccessPointRecordsColumn = useCallback(
        (locationId) => {
            dispatch(fetchAccessPointRecordsColumnStart({ locationId }));
        },
        [dispatch]
    );

    const dispatchOrganizationList = useCallback(() => {
        dispatch(organizationListActions.fetchMasterListStart());
    }, [dispatch]);

    const dispatchSetDateRange = useCallback(
        (dateRange) => {
            dispatch(
                setFilterDateRange({
                    startDate: dateRange.startDate?.toISOString(),
                    endDate: dateRange.endDate?.toISOString(),
                })
            );
        },
        [dispatch]
    );

    const dispatchSetSearchText = useCallback(
        (text) => {
            dispatch(setSearchText(text));
        },
        [dispatch]
    );

    const dispatchSetSelectedFilter = useCallback(
        (filter) => {
            dispatch(setSelectedFilter(filter));
        },
        [dispatch]
    );
    const dispatchSetAllFilter = useCallback(
        (filters) => {
            dispatch(setAllFilter(filters));
        },
        [dispatch]
    );

    const dispatchRemoveAccessPointRecord = useCallback(
        (locationId) => {
            dispatch(removeAccessPointRecordsStart({ locationId }));
        },
        [dispatch]
    );

    const fetchingAccessPointRecordsInProgress = useSelector(selectFetchingAccessPointRecordsInProgress);
    const fetchingAccessPointRecordsError = useSelector(selectFetchingAccessPointRecordsError);

    const fetchingAccessPointRecordsColumnInProgress = useSelector(selectFetchingAccessPointRecordsColumnInProgress);
    const fetchingAccessPointRecordsColumnError = useSelector(selectFetchingAccessPointRecordsColumnError);

    const accessPointRecordsColumnList = useSelector(selectAccessPointRecordsColumnList);
    const selectAccessPointRecordsSelector = useMemo(() => selectAccessPointRecordsDataByTableId(ACCESS_POINT_RECORDS_TABLE_ID), []);
    const { tableData } = useSelector(selectAccessPointRecordsSelector);
    const filterDateRange = useSelector(selectAccessPointRecordsFilterDateRange);
    const searchText = useSelector(selectSearchText);
    const selectedFilter = useSelector(selectSelectedFilter);
    const allFilter = useSelector(selectAllFilter);

    const removingAccessPointRecordInProgress = useSelector(selectRemovingAccessPointRecordInProgress);
    const removingAccessPointRecordError = useSelector(selectRemovingAccessPointRecordError);

    const organizationList = useSelector(selectMasterList);
    const authorizedItems = useSelector(selectAuthoriziedItemList);
    const peopleList = useSelector(authorizedPeopleSelectors.selectAuthoriziedPeopleList);

    const authOrganizationList = useMemo(() => {
        if (!organizationList) return [];

        return organizationList
            .map((organization) => {
                const authOrganization = authorizedItems.find((item) => item.authorizedOrganization.id === organization.id);

                return authOrganization
                    ? {
                          name: organization.name,
                          authTypes: authOrganization.authTypes,
                      }
                    : null;
            })
            .filter((org) => org?.authTypes?.length > 0)
            .sort((a, b) => b.authTypes.length - a.authTypes.length);
    }, [authorizedItems, organizationList]);

    const authPeopleList = useMemo(() => {
        if (!peopleList) return [];

        return peopleList
            .map((person) => ({
                name: `${person.firstName} ${person.lastName}`,
                authTypes: person.authTypes,
            }))
            .filter((person) => person.authTypes?.length > 0)
            .sort((a, b) => b.authTypes.length - a.authTypes.length);
    }, [peopleList]);

    return {
        fetchAccessPointRecords: dispatchFetchAccessPointRecords,
        fetchAccessPointRecordsColumn: dispatchFetchAccessPointRecordsColumn,
        dispatchSetSearchText,
        dispatchSetSelectedFilter,
        dispatchSetAllFilter,
        fetchingAccessPointRecordsInProgress,
        fetchingAccessPointRecordsError,
        accessPointRecordsList: tableData,
        fetchingAccessPointRecordsColumnInProgress,
        fetchingAccessPointRecordsColumnError,
        accessPointRecordsColumnList,

        filterDateRange,
        setFilterDateRange: dispatchSetDateRange,
        searchText,
        selectedFilter,
        allFilter,
        removeAccessPointRecord: dispatchRemoveAccessPointRecord,
        removingAccessPointRecordInProgress,
        removingAccessPointRecordError,
        dispatchOrganizationList,
        authOrganizationList,
        authPeopleList,
    };
};

export default useAccessPointRecord;
