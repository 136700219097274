export const errorCodeToMessageMap = {
    ALREADY_EXIST: 'Already exists',
    PROBLEM_WITH_INSERT_IN_DB: 'Problem with inserting in the database',
    ERROR: 'An error occurred',
    NOT_FOUND: 'Not found',
    PROBLEM_WITH_REMOVING: 'Problem with removing',
    DIFFERENT_LOCATION: 'Different location',
    BAD_REQUEST: 'Bad request',
    NULL_NOT_ALLOWED: 'Null value is not allowed',
    INCONSISTENT_DATA_PROVIDED: 'Inconsistent data provided',
    NOT_A_CAMERA_ELEMENT: 'Not a camera element',
    WRONG_COMPANY_CARD_NUMBER_FORMAT: 'Wrong company card number format',
    INVALID_DATE_OF_BIRTH: 'Invalid birth date',
    FULL_NAME_MISSING: 'Full name is missing',
    DUPLICATE_NAME: 'Duplicate name',
    EMAIL_ALREADY_EXIST: 'Email already exist',
    DUPLICATE_EMAIL: 'Another user with same email already exist.',
};

export const formatErrorCode = (errorCode) => {
    return errorCode.replace(/_/g, ' ').toLowerCase();
};

export const getFormattedErrorMessage = (errorPayload) => {
    if (!errorPayload) return 'An unknown error occurred';

    const errorCodeMatch = errorPayload.match(/\[([^\]]{1,100})\]/);
    if (!errorCodeMatch) return 'An unknown error occurred';

    const errorCode = errorCodeMatch[1];
    return errorCodeToMessageMap[errorCode] || formatErrorCode(errorCode);
};
