import { TextField } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { formControlActions } from 'store/formControl/formControlSlice';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function InputField({
    fieldName,
    initialValue = '',
    value,
    label,
    touched,
    type,
    error,
    formId,
    required,
    disabled,
    lookup,
    blurHandler = () => {},
    changeHandler = () => {},
    onFocus,
    InputProps,
    inputProps,
    helperText,
    multiline,
    minRows,
    classes,
    onKeyPress,
    min,
    max,
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            formControlActions.registerNewFormField({
                formId,
                fieldName,
                initialValue,
                disabled,
                required,
            })
        );

        if (required && initialValue) {
            dispatch(
                formControlActions.setFieldError({
                    formId,
                    fieldName,
                    error: false,
                })
            );
        }
    }, [dispatch, fieldName, formId, initialValue, required]); // eslint-disable-line

    const hasError = required && touched && !!error;
    const inputValue = value ?? initialValue;

    return (
        <TextField
            fullWidth
            className={classes}
            multiline={multiline}
            minRows={minRows}
            value={inputValue}
            disabled={disabled}
            variant="outlined"
            onChange={changeHandler.bind({
                lookup,
                formId,
                fieldName,
                type,
                error,
                required,
            })}
            onBlur={blurHandler.bind({
                required,
                touched,
                error,
                formId,
                fieldName,
            })}
            label={label}
            name={fieldName}
            type={type}
            size="small"
            error={hasError}
            helperText={`${hasError && typeof error === 'string' ? error : helperText && hasError ? helperText : ''} `}
            inputProps={{
                autoComplete: 'off',
                ...inputProps,
                ...(type === 'date' ? { min, max } : {}),
            }}
            InputProps={InputProps}
            InputLabelProps={{ shrink: true }}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
        />
    );
}

export default React.memo(InputField);
