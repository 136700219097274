import { useEffect } from 'react';
import { formControlActions } from 'store/formControl/formControlSlice';

export const useRegisterField = ({ formId, fieldName, initialValue, disabled, required, forceFieldRegistration, dispatch }) => {
    useEffect(() => {
        if (!formId) return;

        dispatch(
            formControlActions.registerNewFormField({
                formId,
                fieldName,
                initialValue,
                disabled: disabled && !forceFieldRegistration,
                required,
            })
        );

        if (required && initialValue) {
            dispatch(
                formControlActions.setFieldError({
                    formId,
                    fieldName,
                    error: false,
                })
            );
        }
    }, [dispatch, formId, fieldName, initialValue, required, disabled, forceFieldRegistration]);
};
