import { useSelector } from 'react-redux';
import { selectSsoEnabled } from 'store/featureFlag/featureFlagSelector';

const useFeatureFlag = () => {
    const isSsoEnabled = useSelector(selectSsoEnabled);

    return {
        isSsoEnabled,
    };
};

export default useFeatureFlag;
